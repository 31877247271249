import { VueQueryPlugin } from '@tanstack/vue-query'
import { createHead } from '@unhead/vue'
import { configure } from 'vee-validate'
import { createApp } from 'vue'
// i18n
import { createI18n } from 'vue-i18n'
import { createRouter } from 'vue-i18n-routing'
import { createWebHistory } from 'vue-router'

import de from '@epostbox/shared/locales/de.json'
import en from '@epostbox/shared/locales/en.json'
import '@epostbox/ui/css'

import App from './app.vue'
import _404 from './views/404.vue'
import EmailVerified from './views/email-verified.vue'
import ForgotPassword from './views/forgot-password.vue'
import Index from './views/index.vue'
import Register from './views/register.vue'
import ResetPassword from './views/reset-password.vue'
import SetupPassword from './views/setup-password.vue'
import Sso from './views/sso.vue'
import Terms from './views/terms.vue'

// VeeValidate

configure({
  validateOnInput: false,
  validateOnModelUpdate: false,
  validateOnChange: false,
  validateOnBlur: false,
})

const i18n = createI18n({
  legacy: false,
  locale: navigator.language.split('-')[0],
  fallbackLocale: 'en',
  messages: {
    en,
    de,
  },
})

const router = createRouter(i18n, {
  version: 4,
  locales: i18n.global.availableLocales,
  defaultLocale: localStorage.getItem('nolas_lang') || 'de',
  history: createWebHistory(),
  routes: [
    {
      path: '/',
      name: 'auth_index',
      component: Index,
      children: [],
      meta: {
        title: 'auth_index',
      },
    },
    {
      path: '/sso',
      name: 'auth_sso',
      component: Sso,
      meta: {
        title: 'auth_sso',
      },
    },
    {
      path: '/register',
      name: 'auth_register',
      component: Register,
      meta: {
        title: 'auth_register',
      },
    },
    {
      path: '/email-verified',
      name: 'auth_email_verified',
      component: EmailVerified,
      meta: {
        title: 'auth_email_verified',
      },
    },
    {
      path: '/forgot-password',
      name: 'auth_forgot_password',
      component: ForgotPassword,
      meta: {
        title: 'auth_forgot_password',
      },
    },
    {
      path: '/reset-password',
      name: 'auth_reset_password',
      component: ResetPassword,
      meta: {
        title: 'auth_reset_password',
      },
    },
    {
      path: '/setup-password',
      name: 'auth_setup_password',
      component: SetupPassword,
      meta: {
        title: 'auth_setup_password',
      },
    },
    {
      path: '/terms',
      name: 'auth_terms',
      component: Terms,
      meta: {
        title: 'auth_terms',
      },
    },
    {
      path: '/:pathMatch(.*)*',
      name: '404',
      component: _404,
      meta: {
        title: '404',
      },
    },
  ],
})

router.onError((error: unknown) => {
  if (
    error instanceof Error &&
    (error.message.includes('Failed to load chunk') ||
      error.message.includes('Loading chunk') ||
      error.message.includes('Unknown chunk'))
  ) {
    window.location.reload()
  }
})

const head = createHead()

createApp(App).use(router).use(i18n).use(head).use(VueQueryPlugin).mount('#app')
