<script setup lang="ts">
import { useHead } from '@unhead/vue'
import { watchEffect, onMounted } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'
import { z } from 'zod'

import { useZod } from '@epostbox/shared/zod'
import { Toaster } from '@epostbox/ui/sonner'
import { TooltipProvider } from '@epostbox/ui/tooltip'

const { errorMap } = useZod()
// @ts-expect-error some types are wrong but it works
z.setErrorMap(errorMap)
const { t } = useI18n()

const route = useRoute()

onMounted(() => {
  if (!localStorage.getItem('nolas_lang')) {
    localStorage.setItem('nolas_lang', 'de')
  }
})

watchEffect(() => {
  const title = route?.meta?.title
  useHead({
    title: title ? `${t(`page_title.auth.${title}`)} | nolas Network` : 'nolas Network',
  })
})
</script>

<template>
  <TooltipProvider>
    <Toaster rich-colors position="bottom-center" close-button />
    <RouterView />
  </TooltipProvider>
</template>
