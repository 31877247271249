<script setup lang="ts">
import { Checkbox } from '@epostbox/ui/checkbox'

const modelValue = defineModel<boolean>()
</script>

<template>
  <div class="mb-6 flex items-center space-x-2">
    <Checkbox id="terms" v-model:checked="modelValue" />
    <Label
      for="terms"
      class="text-xs font-medium leading-none text-gray-700 peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
    >
      {{ $t('auth.common.terms.string') }}
      <router-link to="/terms" class="text-crm-blue-600 underline" data-e2e="terms-link">{{
        $t('auth.common.terms.cta')
      }}</router-link>
    </Label>
  </div>
</template>
