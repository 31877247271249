<script setup lang="ts">
import { toTypedSchema } from '@vee-validate/zod'
import { useForm } from 'vee-validate'
import { onMounted, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'

import { getUrlParamsForAuth } from '@epostbox/shared/client'
import { Alert } from '@epostbox/ui/alert'
import { Button } from '@epostbox/ui/button'
import { FormField } from '@epostbox/ui/form'
import { Input } from '@epostbox/ui/input'
import { CardWrapper } from '@epostbox/ui/organisms/card-wrapper'

import { Login } from '@composables/schemas'
import { useLogin } from '@composables/use-login'

const route = useRoute()
const router = useRouter()
const authParams = ref()

// eslint-disable-next-line unicorn/prefer-top-level-await
getUrlParamsForAuth(import.meta.env.VITE_APP_URL)
  .then(urlParams => {
    authParams.value = urlParams
  })
  // eslint-disable-next-line unicorn/prefer-top-level-await
  .catch(error => {
    console.error('Error:', error.message)
  })

onMounted(() => {
  router.replace({
    path: route.path,
    query: {
      client_id: authParams.value?.client_id,
      redirect_uri: authParams.value?.redirect_uri,
      response_type: authParams.value?.response_type,
      code_challenge: authParams.value?.code_challenge,
      code_challenge_method: authParams.value?.code_challenge_method,
      code_verifier: authParams.value?.code_verifier,
      token: authParams.value?.token,
    },
  })
  const token = new URL(location.href).searchParams.get('token')
  router.replace({ path: route.path, query: { ...(token && { token }) } })
})

const form = useForm({
  validationSchema: toTypedSchema(Login),
  initialValues: {
    email: undefined,
  },
})

const { login, error, isPending } = useLogin()
const token = new URL(location.href).searchParams.get('token')

const onSubmit = form.handleSubmit(async values => {
  if (error.value?.name === 'IdleAuthenticationError') {
    location.replace(`${import.meta.env.VITE_APP_URL}`)
    return
  }

  await login({ ...values, ...(token && { token }) })
})
</script>

<template>
  <CardWrapper :description="$t('auth.flow.login.description')">
    <template #sso-button>
      <Button as="router-link" to="/sso" variant="social" size="lg" class="mb-8 w-full" data-e2e="login-sso">
        {{ $t('auth.button.sso') }}
      </Button>
    </template>
    <Alert v-if="error" variant="destructive" class="mb-4" :description="$t(`common.error.${error.name}`)" />
    <form autocomplete="off" novalidate @submit.prevent="onSubmit">
      <FormField v-slot="{ field }" :form="form" name="email">
        <Input type="email" :label="$t('auth.field.email.label')" autocomplete="email" v-bind="field" />
      </FormField>
      <FormField v-slot="{ field }" :form="form" name="password">
        <Input type="password" :label="$t('auth.field.password.label')" autocomplete="password" v-bind="field" />
      </FormField>
      <Button
        as="router-link"
        to="/forgot-password"
        variant="link"
        size="sm"
        type="submit"
        class="mb-6 w-max"
        data-e2e="forgot-password"
      >
        {{ $t('auth.button.forgot_password') }}
      </Button>
      <Button variant="primary" type="submit" size="lg" class="w-full" :loading="isPending" data-e2e="login">
        {{ $t(error?.name === 'IdleAuthenticationError' ? 'auth.button.try_again' : 'auth.button.login') }}
      </Button>
      <Button
        as="router-link"
        :to="token ? `/register?token=${token}` : `/register`"
        variant="link"
        size="md"
        type="submit"
        class="mx-auto mt-4 w-max"
        data-e2e="register"
      >
        {{ $t('auth.button.register') }}
      </Button>
    </form>
  </CardWrapper>
</template>
